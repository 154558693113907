<template>
  <div id="brainstorm-container">
    <h2><i class="fad fa-fw fa-cogs"></i> Debug</h2>
    <p class="lead">
      Debug Vue $store
    </p>

    <button class="btn btn-primary mb-3" @click="updateUser">User in Datenbank updaten</button>

    <div class="btn-group btn-block">
      <button @click="step = 'cluster'" class="btn" :class="step === 'cluster' ? 'btn-primary' : 'btn-light'">
        Cluster
      </button>
      <button @click="step = 'export'" class="btn" :class="step === 'export' ? 'btn-primary' : 'btn-light'">
        Export
      </button>
    </div>

    <div class="mt-3">
      <div v-if="step === 'cluster'">
        <h3>DB Model</h3>

        <div class="row">
          <div v-for="(prop, index) in Object.keys(store)" :key="index" class="col-lg-6 mb-5">
            <div class="card">
              <div class="card-body bg-light">
                <DebugItem :obj="store[prop]" :prop="prop"></DebugItem>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 'export'">
        <h3>Export</h3>
        <div>
          <textarea name="structure-output" id="structure-output" :value="JSON.stringify(store)" disabled class="form-control h-100"></textarea>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import DebugItem from "@/components/DebugItem";

export default {
  name: 'Debug',
  components: {
    DebugItem
  },
  data() {
    return {
      step: "cluster",
    }
  },
  computed: {
    store() {
      return this.$store.state;
    },
  },
  methods: {
    updateUser() {
      this.$store.dispatch('project/updateProject');
    }
  },
  beforeMount() {
  }
}
</script>