<template>
  <div>
    <p class="mb-0">
      {{ prop }}&nbsp;
      <span class="float-right">
        &lt;{{ typeOf(obj) === 'array' ? ('array[' + typeOf(obj[0]) + ']') : typeOf(obj) }}&gt;
      </span>
    </p>

    <ul v-if="typeOf(obj) === 'object'" class="list-group mt-3">
      <li v-for="(nextProp, index) in Object.keys(obj)" class="list-group-item list-group-item-sm" :key="index">
        <DebugItem :obj="obj[nextProp]" :prop="nextProp"></DebugItem>
      </li>
    </ul>

    <ul v-else-if="typeOf(obj) === 'array' && typeOf(obj[0]) === 'object'" class="list-group mt-3">
      <li v-for="(nextProp, index) in Object.keys(obj[0])" class="list-group-item list-group-item-sm" :key="index">
        <DebugItem :obj="obj[0][nextProp]" :prop="nextProp"></DebugItem>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'DebugItem',
  components: {},
  directives: {},
  props: {
    obj: {
      required: true
    },
    prop: String,
  },
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  methods: {
    typeOf(obj) {
      try {
        return {}.toString.call(obj).split(' ')[1].slice(0, -1).toLowerCase();
      } catch (e) {
        console.log("FAIL FUNCTION", e);
      }
    }
  },
}
</script>

<style scoped></style>